import React from "react";

import { AppContextProvider } from "./src/context/app-context";
import { DrawerContextProvider } from "./src/context/drawer-context";

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>
    <DrawerContextProvider>{element}</DrawerContextProvider>
  </AppContextProvider>
);
