// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-cities-jsx": () => import("./../../../src/templates/cities.jsx" /* webpackChunkName: "component---src-templates-cities-jsx" */),
  "component---src-templates-electricite-jsx": () => import("./../../../src/templates/electricite.jsx" /* webpackChunkName: "component---src-templates-electricite-jsx" */),
  "component---src-templates-guepes-jsx": () => import("./../../../src/templates/guepes.jsx" /* webpackChunkName: "component---src-templates-guepes-jsx" */),
  "component---src-templates-nuisible-jsx": () => import("./../../../src/templates/nuisible.jsx" /* webpackChunkName: "component---src-templates-nuisible-jsx" */),
  "component---src-templates-piscine-jsx": () => import("./../../../src/templates/piscine.jsx" /* webpackChunkName: "component---src-templates-piscine-jsx" */),
  "component---src-templates-plomberie-jsx": () => import("./../../../src/templates/plomberie.jsx" /* webpackChunkName: "component---src-templates-plomberie-jsx" */)
}

